import Phone from "./Phone";

const Phones = (props) => {
  const {
    currentPage,
    homeProjects,
    setHomeProjects,
    isMobile
  } = props;

    return (
      <section className="home__outer-wrapper">
        <div className="home__inner-wrapper">
          {homeProjects &&
            homeProjects.map((project, index) => {
              return (
                <Phone
                  homeProjects={homeProjects}
                  setHomeProjects={setHomeProjects}
                  currentPage={currentPage}
                  project={project}
                  key={index}
                  index={index}
                  isMobile={isMobile}
                />
              );
            })}
          <div className="home__spacer"></div>
        </div>
      </section>
    );
};

export default Phones;
