import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { getFile } from "@sanity/asset-utils";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const BackgroundMedia = (props) => {

    const { 
        homeProjects,
        headerIsBlurred,
        isMobile,
      } = props;

  return (
    <section
        className={`home__background-image-wrapper ${
          headerIsBlurred ? "blurred" : ""
        }`}
      >
        {homeProjects &&
          homeProjects.map((project, index) => {
            if (project.mainVideo !== null && !isMobile) {
              const src = getFile(project.mainVideo, sanityClient.config());
              return(
                <div
                key={index}
                data-id={project._id}
                className={`home__background-image`}
                style={{
                  opacity: project.active || project.selected ? "1" : "0",
                }}
              >
                {index}
                <video src={src.asset.url} 
                autoPlay={true}
                muted={true}
                playsInline={true}
                loop={true}
                className="home__background-video"
                ></video>
              </div>
              );
            } else {
              return (
                <div
                  key={index}
                  data-id={project._id}
                  className={`home__background-image`}
                  style={{
                    backgroundImage: `url(${urlFor(project.mainImage)})`,
                    opacity: project.active || project.selected ? "1" : "0",
                  }}
                ></div>
              );
            }
          })}
      </section>
  )
}

export default BackgroundMedia