import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function ProjectImage(props) {
  const { media, project } = props;


  const smallUrl = `${urlFor(media.imageMedia)}?q=10`;
  const largeUrl = `${urlFor(media.imageMedia)}`;
  
    const [src, { blur }] = useProgressiveImg(
    smallUrl,
    largeUrl
  );

  return (
    <div className="project__media-wrapper">
        <img
          src={src}
          alt={`DAPHNEE LANTERNIER - ${project.title}`}
          className="project__media-image"
          style={{
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out",
          }}
        />
    </div>
  );
}

export default ProjectImage;
