import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../../hooks/usePortableTextComponents";

const Contact = (props) => {

    const {       
        contactDetails,
        setContactActive,
        creditsActive,
        setCreditsActive
    } = props;
    
    const handleClick =()=>{
        setCreditsActive((prev)=>!prev);
        setContactActive(false);
     }

  return (
    <>
    <button className='credits__button button-background' onClick={handleClick}>
        {creditsActive?"CLOSE":"INFO"}
    </button>
    <section 
    className={`credits__wrapper ${creditsActive?"active":""}`}
    onClick={()=>setCreditsActive(false)}>
        <div>
        <PortableText value={contactDetails.bio} components={UnderlineLink} />
        <div>Website by <a href="https://www.voidmain.studio/" target="_blank" rel="noreferrer">VOID MAIN()</a></div>
        </div>
    </section>
    </>
  )
}

export default Contact