import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client.js";

import Credits from "./Credits.js";
import Contact from "./Contact.js";

const Navigation = (props) => {
  const { currentPage } = props;

  const [contactDetails, setContactDetails] = useState(null);
  const [contactActive, setContactActive] = useState(false);
  const [creditsActive, setCreditsActive] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "information"][0]`)
      .then((data) => {
        setContactDetails(data);
      })
      .catch();
  }, []);

  return (
    <>
      <Link to="/" className="navigation__logo button-background">
        DAPHNEE LANTERNIER LTD.
      </Link>
      <Link
        to="/"
        className={`navigation__back button-background
        ${currentPage === "home" ? "" : "active"}`}
      >
        BACK
      </Link>
      <Link to="/archive" className="navigation__archive button-background">
        ARCHIVE
      </Link>
      {contactDetails ? (
        <>
          <Credits
            {...{
              contactDetails,
              setContactActive,
              creditsActive,
              setCreditsActive,
            }}
          />
          <Contact
            {...{
              contactDetails,
              setCreditsActive,
              contactActive,
              setContactActive,
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Navigation;
