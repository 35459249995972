const Contact = (props) => {

    const {
        contactDetails,
        contactActive,
        setContactActive,
        setCreditsActive
     } = props;

     const handleClick =()=>{
        setContactActive((prev)=>!prev);
        setCreditsActive(false);
     }

  return (
    <>
    <button className='contact__button button-background' onClick={handleClick}>
        {contactActive?"CLOSE":"CONTACT"}
    </button>
    <section 
    className={`contact__wrapper ${contactActive?"active":""}`}
    onClick={()=>setContactActive(false)}>
        <div>
        <a href={`mailto:${contactDetails.emailAddress}`} className='contact__contact button-background'>EMAIL</a>
        {contactDetails.socials.map((social, index)=>{
            return (
            <a href={social.entryLink} className='contact__contact button-background' key={index} target='_blank' rel='noreferrer'>{social.entryName}</a>)
        })}
        </div>
    </section>
    </>
  )
}

export default Contact