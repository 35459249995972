import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";
import { useState } from "react";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ArchiveImage = (props) => {
  const { image, project, setFocusImageSrc, setFocusActive, isMobile, setFocusImageClient} = props;

  const smallUrl = `${urlFor(image)}?w=300&h=300&q=5`;
  const largeUrl = `${urlFor(image)}?w=300&h=300&q=40`;

  const mobileUrl = `${urlFor(image)}?w=100&h=100&q=40`

  const fullSrc = `${urlFor(image)}`;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  const [position] = useState({
    top: !isMobile?(Math.random() * (window.innerHeight * 2.8 - 500)):(Math.random() * (window.innerHeight * 3)),
    left: !isMobile?(Math.random() * (window.innerWidth * 2.8 - 500)):(Math.random() * (window.innerWidth * 4)),
    rotation: Math.random() * 180 - 90,
  });

  const handleClick = () => {
    setFocusActive(true);
    setFocusImageSrc(fullSrc);
    setFocusImageClient(project.client);
  };

  return (
    <div
      className="archive__image-wrapper"
      style={{
        top: position.top,
        left: position.left,
        transform: `rotate(${position.rotation}deg)`,
      }}
      onClick={handleClick}
    >
      <img
        src={!isMobile?src:mobileUrl}
        alt={`DAPHNEE LANTERNIER - ${project.title}`}
        className="archive__image"
        data-type="image"
        style={isMobile?{
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out",
        }:{}}
      />
    </div>
  );
};

export default ArchiveImage;
