import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import sanityClient from "./client.js";

import Home from "./views/Home";
import HomeRoute from "./views/HomeRoute";
import Archive from "./views/Archive";
import Project from "./views/Project";
import Navigation from "./components/global/Navigation";

import "./styles/global-styles.scss";
import Loading from "./components/global/Loading.js";

function App() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkWidth();
    window.addEventListener("resize", checkWidth, false);
  });

  const [homeProjects, setHomeProjects] = useState(null);
  const [headerIsBlurred, setHeaderIsBlurred] = useState(true);

  // const shuffleArray = (array) => {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  // };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"][0]{
            projects[]->{
              _id,
              title,
              slug,
              mainImage,
              mainVideo
            }
          }`
      )
      .then((data) => {
        // const newArray = [...data.projects];
        // shuffleArray(newArray);
        // setHomeProjects(newArray);
        setHomeProjects(data.projects);
      })
      .catch();
  }, []);


  if (homeProjects) {
    return (
      <>
        <Navigation
          {...{
            currentPage,
          }}
        />
        <Home
          {...{
            currentPage,
            homeProjects,
            setHomeProjects,
            headerIsBlurred,
            isMobile
          }}
        />

        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route
              element={
                <HomeRoute
                  key={"home"}
                  {...{
                    setCurrentPage,
                    homeProjects,
                    setHomeProjects,
                    setHeaderIsBlurred,
                  }}
                />
              }
              path="/"
            />
            <Route
              element={
                <Archive
                  key={"archive"}
                  {...{
                    setCurrentPage,
                    homeProjects,
                    setHomeProjects,
                    setHeaderIsBlurred,
                    isMobile,
                  }}
                />
              }
              path="/archive"
            />
            <Route
              element={
                <Project
                  {...{
                    setCurrentPage,
                    homeProjects,
                    setHomeProjects,
                    setHeaderIsBlurred,
                  }}
                />
              }
              path="/projects/:slug"
            />
          </Routes>
        </AnimatePresence>
      </>
    );
  } else {
    return <Loading />;
  }
}

export default App;
