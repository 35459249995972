import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import sanityClient from "../client.js";

import Loading from "../components/global/Loading";
import ArchiveProject from "../components/archive/ArchiveProject.js";
import ArchiveImageFocus from "../components/archive/ArchiveImageFocus.js";

const Archive = (props) => {
  const { setCurrentPage, homeProjects, setHomeProjects, setHeaderIsBlurred, isMobile } =
  props;
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    setCurrentPage("archive");
    setHeaderIsBlurred(true);
    const newProjects = [...homeProjects];
    newProjects.forEach((project) => {
      project.active = false;
      project.selected = false;
      project.disabled = true;
    });
    setHomeProjects(newProjects);
  },[]);


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"]{
          _id,
          title,
          slug,
          client,
          mainImage,
          mainVideo,
          media
      }`
      )
      .then((data) => {
        const filter = data.filter(obj2 => !homeProjects.some(obj1 => obj1._id === obj2._id));
        setPageData(filter);
      })
      .catch();
  }, []);

  const [focusActive, setFocusActive] = useState(false);
  const [focusImageSrc, setFocusImageSrc] = useState("");
  const [focusImageClient, setFocusImageClient] = useState(null);

  const EasingFactor = 0.05;
  const [position, setPosition] = useState({ left: 10, top: 10 });
  const lastMousePosition = useRef({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    lastMousePosition.current = { x: -clientX, y: -clientY };
  };

  useEffect(() => {
    const updatePosition = () => {
      const { x, y } = lastMousePosition.current;
      const targetX = (x / window.innerWidth) * 100;
      const targetY = (y / window.innerHeight) * 100;

      const newLeft = targetX * 2 + 0;
      const newTop = targetY * 2 + 0;

      const dx = newLeft - position.left;
      const dy = newTop - position.top;

      const nextPosition = {
        left: position.left + dx * EasingFactor,
        top: position.top + dy * EasingFactor,
      };

      setPosition(nextPosition);
    };

    // if (!isMobile) {
      const animationId = !isMobile?requestAnimationFrame(updatePosition):null;
    // }

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [position, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMobile]);

  if (pageData) {
    return (
      <>
        <motion.main
          key={"archive-wrapper"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4 } }}
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
          className="archive__canvas-wrapper"
        >
          <div
            className="archive__canvas-inner"
            style={{ left: `${position.left}%`, top: `${position.top}%` }}
          >
            <div className="archive__canvas-inner-inner">
              {pageData.map((project, index) => {
                return (
                  <ArchiveProject
                    project={project}
                    key={index}
                    setFocusActive={setFocusActive}
                    setFocusImageSrc={setFocusImageSrc}
                    isMobile={isMobile}
                    setFocusImageClient={setFocusImageClient}
                  />
                );
              })}
            </div>
          </div>
        </motion.main>
        <ArchiveImageFocus
          {...{
            focusActive,
            setFocusActive,
            focusImageSrc,
            setFocusImageSrc,
            focusImageClient
          }}
        />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Archive;
