import React, { useEffect, useRef } from "react";

const ProjectSpacer = (props) => {
  const { setHeaderIsBlurred, homeProjects, setHomeProjects, pageData } = props;
  const spacer = useRef();

  useEffect(() => {
    const checkPosition = () => {
      const height = window.innerHeight / 2;
      const scrollPos = window.scrollY;
      if (scrollPos > height) {
        setHeaderIsBlurred(false);
        const newProjects = [...homeProjects];
        newProjects.forEach((project) => {
          if (pageData._id === project._id) {
            project.disabled = true;
          }
        });
        setHomeProjects(newProjects);
      } else {
        setHeaderIsBlurred(true);
        const newProjects = [...homeProjects];
        newProjects.forEach((project) => {
          if (pageData._id === project._id) {
            project.disabled = false;
          }
        });
        setHomeProjects(newProjects);
      }
    };

    window.addEventListener("scroll", checkPosition, false);

    // return window.removeEventListener('scroll', checkPosition, false)
  });
  return (
    <>
      <div className="project__spacer" ref={spacer}></div>
      <div className="project__spacer"></div>
      <div className="project__spacer"></div>
      <div className="project__spacer"></div>
    </>
  );
};

export default ProjectSpacer;
