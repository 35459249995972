import { useRef } from "react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";
import { getFile } from "@sanity/asset-utils";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Phone = (props) => {
  const { project, index, currentPage, homeProjects, setHomeProjects, isMobile } = props;

  const smallUrl = project.mainImage
    ? `${urlFor(project.mainImage)}?q=10`
    : null;
  const largeUrl = project.mainImage ? `${urlFor(project.mainImage)}` : null;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  const vidSrc = project.mainVideo
    ? getFile(project.mainVideo, sanityClient.config())
    : null;

  const ref = useRef(null);

  return (
    <Link
      to={`/projects/${project.slug.current}`}
      className={`
      phone__outer-wrapper 
      ${project.selected ? "fixed" : ""}
      ${currentPage === "project" && !project.selected ? "disabled" : ""}
      ${project.disabled ? "disabled" : ""}
      `}
      ref={ref}
      onPointerEnter={() => {
        const newProjects = [...homeProjects];
        newProjects[index].active = true;
        setHomeProjects(newProjects);
      }}
      onPointerMove={() => {
        const newProjects = [...homeProjects];
        newProjects[index].active = true;
        setHomeProjects(newProjects);
      }}
      onPointerLeave={() => {
        const newProjects = [...homeProjects];
        newProjects[index].active = false;
        setHomeProjects(newProjects);
      }}
      onClick={() => {
        const newProjects = [...homeProjects];
        newProjects[index].active = true;
        newProjects[index].selected = true;
        setHomeProjects(newProjects);
      }}
    >
      <div className="phone__media-wrapper">
        {/* {src !== null ? (
          <img
            src={src}
            alt={`DAPHNEE LANTERNIER - ${project.title}`}
            className="phone__media"
            style={{
              filter: blur ? "blur(20px)" : "none",
              transition: blur ? "none" : "filter 0.3s ease-out",
            }}
          />
          ) : (
          ""
        )} */}
        {project.mainVideo !== null && !isMobile ? (
          <video
            src={vidSrc.asset.url}
            autoPlay={true}
            muted={true}
            playsInline={true}
            loop={true}
            className="phone__video"
          ></video>
        ) : (
          <img
            src={src}
            alt={`DAPHNEE LANTERNIER - ${project.title}`}
            className="phone__media"
            style={{
              filter: blur ? "blur(20px)" : "none",
              transition: blur ? "none" : "filter 0.3s ease-out",
            }}
          />
        )}
      </div>
    </Link>
  );
};

export default Phone;
