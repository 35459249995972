import React from "react";

const ProjectHeader = (props) => {
  const { project } = props;

  return (
    <div className="project__header-outer-wrapper">
      <div className="project__header-inner-wrapper">
        <div className="project__header-item button-background">
            PROJECT:<br />
            {project.title}
        </div>
        <div className="project__header-item button-background">
            CLIENT:<br />
            {project.client}
        </div>
        <div className="project__header-item button-background">
            LOCATION:<br />
            {project.location}
        </div>
        <div className="project__header-item button-background">
            YEAR:<br />
            {project.year}
        </div>
      </div>
    </div>
  );
};

export default ProjectHeader;
