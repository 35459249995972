import BackgroundMedia from "../components/home/BackgroundMedia";
import Phones from "../components/home/Phones";

const Home = (props) => {
  const { currentPage, homeProjects, setHomeProjects, headerIsBlurred, isMobile } = props;
  return (
    <>
      <BackgroundMedia
        {...{
          currentPage,
          homeProjects,
          headerIsBlurred,
          isMobile
        }}
      />
      <Phones
        {...{
          currentPage,
          homeProjects,
          setHomeProjects,
          isMobile
        }}
      />
    </>
  );
};

export default Home;
