export const UnderlineLink = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" ? "noreferrer":""}
            style={{textDecoration: 'underline'}}
          >
            {children}
          </a>
        );
      },
    },
  };