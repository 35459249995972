const ArchiveImageFocus = (props) => {

    const {
        focusActive,
        setFocusActive,
        focusImageSrc,
        setFocusImageSrc,
        focusImageClient
    } = props;

    const handleClick = () =>{
        setFocusActive(false);
        let timeout = setTimeout(()=>{
            setFocusImageSrc('');
        },500)
    }
  return (
    <button className={`archive__focus-wrapper ${focusActive?'active':''}`} onClick={handleClick}>
        <div className='archive__focus-image' style={{backgroundImage:`url(${focusImageSrc})`}}></div>
        <div className='archive__focus-close button-background' onClick={handleClick}>Close</div>
        {focusImageClient!==null?(
            <div className="archive__client-name button-background">{focusImageClient}</div>
        ):('')}
        
    </button>
  )
}

export default ArchiveImageFocus