import React from 'react'
import ArchiveImage from './ArchiveImage';

const ArchiveProject = (props) => {

    const { project, setFocusActive, setFocusImageSrc, isMobile, setFocusImageClient } = props;

  return (
    <>
        <ArchiveImage project={project} image={project.mainImage} setFocusImageSrc={setFocusImageSrc} setFocusActive={setFocusActive} setFocusImageClient={setFocusImageClient}/>

        {project.media.map((media, index)=>{
            if(media._type === "mediaImage"){
                if(media.imageMedia && media.imageMedia.asset){
                    return <ArchiveImage 
                    project={project} 
                    image={media.imageMedia.asset} 
                    key={index}
                    setFocusActive={setFocusActive}
                    setFocusImageSrc={setFocusImageSrc}
                    isMobile={isMobile}
                    setFocusImageClient={setFocusImageClient}
                    />
                } 
            } 
        })}
    </>
  )
}

export default ArchiveProject