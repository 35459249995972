import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import sanityClient from "../client";

import Loading from "../components/global/Loading";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectSpacer from "../components/project/ProjectSpacer";
import ProjectImage from "../components/project/ProjectImage";
import ProjectVimeo from "../components/project/ProjectVimeo";

const Project = (props) => {
  const {
    homeProjects,
    setHomeProjects,
    setCurrentPage,
    setHeaderIsBlurred,
  } = props;
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);
  const [isEntered, setIsEntered] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]{
        ...,
        projectMedia
      }`, { slug })
      .then((data) => {
        setPageData(data[0]);
      })
      .catch();
  }, [slug]);

  useEffect(() => {
    setCurrentPage("project");
    // setHeaderIsBlurred(false);

    if (homeProjects && pageData) {
      if (isEntered === false) {
        const newProjects = [...homeProjects];
        newProjects.forEach((project) => {
          if (project._id === pageData._id) {
            project.active = true;
            project.selected = true;
          }
        });
        setHomeProjects(newProjects);
        setIsEntered(true);
      }
    }
  }, [homeProjects, setHomeProjects, pageData, isEntered, setHeaderIsBlurred]);

  if (pageData && pageData.media) {
    return (
      <motion.main
        key={"project-wrapper"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="project__outer-wrapper"
      >
        <ProjectHeader project={pageData} />
        <ProjectSpacer {...{
          homeProjects, 
          setHomeProjects,
          setHeaderIsBlurred,
          pageData
        }} />
        {pageData.media &&
          pageData.media.map((media, index) => {
            if (media._type === "mediaImage") {
              return (
                <ProjectImage project={pageData} media={media} key={index} />
              )
            } else if (media._type === "mediaVimeo") {
              return (
                <ProjectVimeo project={pageData} media={media} key={index} />
              );
            } else {
              return;
            }
          })}
      </motion.main>
    );
  } else {
    return <Loading />;
  }
};

export default Project;
