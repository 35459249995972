import { useEffect } from "react";

const HomeRoute = (props) => {
  const { setCurrentPage, homeProjects, setHomeProjects, setHeaderIsBlurred } =
    props;

  useEffect(() => {
    setCurrentPage("home");
    setHeaderIsBlurred(true);
    const newProjects = [...homeProjects];
    newProjects.forEach((project) => {
      project.active = false;
      project.selected = false;
      project.disabled = false;
    });
    setHomeProjects(newProjects);
  },[]);

  return <></>;
};

export default HomeRoute;
