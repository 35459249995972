import { useEffect, useState, useRef } from "react";
import Plyr from "plyr-react";
import "./plyr-styles/plyr.scss";

function ProjectVimeo(props) {
  const { media } = props;
  const [plyrProps, setPlyrProps] = useState({});

  const playerWrapper = useRef()
  const player = useRef()

  const handleScroll = () => {
    let top = playerWrapper.current.getBoundingClientRect().top
    let bottom = top + playerWrapper.current.offsetHeight;
    if(top > window.innerHeight / 2 || bottom < window.innerHeight / 2 ){
      player.current.plyr.pause();
    }
  }

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll);
    return()=> {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setPlyrProps({
      source: {
        type: "video",
        sources: [
          {
            src: media.title,
            provider: "vimeo",
          },
        ],
      },
      options: {
        controls: ["play", "mute", "progress"],
      },
    });
  }, [media.title]);

  return (
    <>
      <div className="project__media-wrapper vimeo" ref={playerWrapper}>
        <Plyr {...plyrProps} ref={player}/>
      </div>
    </>
  );
}

export default ProjectVimeo;
